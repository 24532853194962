import React from 'react';
import { Vehicle } from '../../App';
import { StyledButton, StyledContainer, StyledSellerName, StyledStand } from './VehicleSeller.styles';

interface VehicleSellerProps {
  vehicle: Vehicle;
  setRetoma: (value: boolean) => void;
}

const VehicleDetailComponent: React.FC<VehicleSellerProps> = ({ vehicle, setRetoma }) => {

  const handleOpenRetoma = () => {
    setRetoma(true);
  }
  
  const handleOpenContact = () => {
    setRetoma(false);
  }

  return (
    <StyledContainer>
      <StyledStand>Vendido por:</StyledStand>
      <StyledSellerName>{vehicle.sede}</StyledSellerName>
      <StyledButton variant="primary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleOpenContact}>Contactar Vendedor</StyledButton>
      <StyledButton variant="secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleOpenRetoma}>Avaliação de Retoma</StyledButton>
    </StyledContainer>
  );
};

export default VehicleDetailComponent;