const primaryColor = "#7EC242"; // green
const secondaryColor = "#2F3234"; // dark grey
const white = "white";
const grey = "grey";
const grey1 = "#EBECEF";

const gradient1 = "#e1ebdd";
const gradient2 = "#90B180";
const gradient3 = "#5F7A64";
const gradient4 = "#727272";

export const CarpickTheme = {
  generic: {
    colors: {
      primary: primaryColor,
      secondary: secondaryColor,
      grey: grey1,
      darkGradient: gradient4,
    },
    backgroundColor: white,
    container: {
      maxWidth: "1200px",
    },
    separator: {
      color: gradient1
    },
    button: {
      color: white,
      backgroundColor: primaryColor,
    }
  },
  filters: {
    backgroundColor: white,
    color: secondaryColor,
    checkbox: primaryColor,
    borderRadius: "4px",
    hover: {
      backgroundColor: gradient1,
      color: secondaryColor,
    },
    active: {
      backgroundColor: primaryColor,
      color: white,
    },
    button: {
      backgroundColor: secondaryColor,
      color: white,
      container: white,
    },
  },
  sort: {
    backgroundColor: gradient1,
    color: secondaryColor,
    hover: {
      backgroundColor: gradient1,
      color: secondaryColor,
    },
    active: {
      backgroundColor: primaryColor,
      color: white,
    },
  },
  vehicleDetail: {
    backgroundColor: gradient1,
  },
  vehicleList: {
    backgroundColor: white,
    filter: {
      backgroundColor: gradient1,
    },
    pageTitle: {
      color: secondaryColor,
      fontSize: "28px",
      fontWeight: "600",
      highlight: {
        color: primaryColor,
      }
    },
    vehicle: {
      backgroundColor: white,
      border: "1px solid #dcdde0",
      borderRadius: "8px",
      header: {
        fontSize: "20px",
        fontWeight: "bold",
        color: secondaryColor,
      },
      subheader: {
        fontSize: "14px",
        fontWeight: "400",
        color: grey,
      },
      price: {
        fontSize: "20px",
        fontWeight: "500",
        color: secondaryColor,
      },
      details: {
        fontSize: "12px",
        fontWeight: "400",
        color: secondaryColor,
        backgroundColor: gradient1,
        borderRadius: "8px",
      },
    },
    text: {
      color: secondaryColor,
      fontSize: "16px",
      fontWeight: "400"
    },
    price: {
      color: primaryColor,
      fontSize: "16px",
      fontWeight: "400"
    },
  },
  pagination: {
    color: primaryColor,
    backgroundColor: gradient1,
    hover: {
      color: white,
      backgroundColor: primaryColor,
    }
  },
  highlight: {
    color: white,
    backgroundColor: primaryColor,
  }
};