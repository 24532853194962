import React from 'react';
import { StyledCheckSvg, StyledContainer, StyledDetailsTitle, StyledFeature, StyledRow } from './VehicleEquipmentComponent.styles';
import { IEquipment } from '../../App';

interface VehicleEquipmentProps {
  equipment: IEquipment;
}

interface IMenuEquipment {
  title: string;
  key: keyof IEquipment;
}


const VehicleEquipmentComponent: React.FC<VehicleEquipmentProps> = ({ equipment }) => {

  const menu: IMenuEquipment[] = [
    { title: 'Conforto', key: 'confort'},
    { title: 'Exterior', key: 'exterior'},
    { title: 'Interior', key: 'interior'},
    { title: 'Ficha técnica', key: 'ficha_tecnica'},
    { title: 'Aúdio e Multimédia', key: 'multimedia'},
    { title: 'Segurança', key: 'seguridad'},
  ]

  return (
    <StyledContainer>
      <StyledDetailsTitle>Equipamento</StyledDetailsTitle>
      <div className="accordion" id="accordionExample">
        {menu.map((menuItem: IMenuEquipment, index) => (
          <div key={"collapse"+index} className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+index}>
                {menuItem.title}
              </button>
            </h2>
            <div id={"collapse"+index} 
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample">
              <div className="accordion-body">
                {equipment[menuItem.key].elemento.map((item) =>
                  <StyledRow>
                    <StyledCheckSvg />
                    <StyledFeature>{item}</StyledFeature>
                  </StyledRow>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
    </StyledContainer>
  );
};

export default VehicleEquipmentComponent;