import styled from "styled-components";
import { ReactComponent as Close} from '../../assets/x.svg'

export const StyledDropdown = styled.div`
  margin: 12px 0;
  width: 18%; // 4 spaces = 8%

  @media (max-width: 1000px)  {
    width: 31%; // 3 spaces = 6%
  }
  @media (max-width: 678px)  {
    width: 47%; // 2 spaces = 6%
  }
  @media (max-width: 480px)  {
    width: 100%;
  }
  
  .dropdown > .btn {
    width: 100%;
    height: 50px;
    color: ${({ theme }) => theme.filters.color};
    background-color: ${({ theme }) => theme.filters.backgroundColor};
    border: 1px solid ${({ theme }) => theme.filters.backgroundColor};
    border-radius: ${({ theme }) => theme.filters.borderRadius};
    padding: 10px 12px;
  }

  .highlight {
    font-weight: bold;
    color: ${({ theme }) => theme.generic.colors.secondary};
  }
  .dropdown-toggle::after {
    margin-left: 12px;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: ${({ theme }) => theme.sort.active.color};
    background-color: ${({ theme }) => theme.sort.active.backgroundColor};
  }

  .dropdown-item:hover {
    color: ${({ theme }) => theme.sort.hover.color};
    background-color: ${({ theme }) => theme.sort.hover.backgroundColor};
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    width: 100%;
    max-height: 250px;
    overflow-y: auto;

  }

  .form-check-label,
  .form-check-input,
  .form-check {
    cursor: pointer;
  }

  .form-check-input:checked {
    background-color: ${({ theme }) => theme.filters.checkbox};
    border-color: ${({ theme }) => theme.filters.checkbox};
  }
`

export const StyledCounter = styled.span`
  color: ${({ theme }) => theme.vehicleList.pageTitle.highlight.color};
  font-size: 14px;
  font-weight: 600;
  vertical-align: top;
`

export const StyledChevronImg = styled.img`
  margin-top: 2px;
`
export const StyledCloseSvg = styled(Close)`
  fill: ${({ theme }) => theme.filters.checkbox};
  width: 28px;
  height: 28px;
`