import styled from "styled-components";

export const StyledContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.generic.separator.color};
  max-width: ${({ theme }) => theme.generic.container.maxWidth};
  margin: 24px auto 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`

export const StyledDetailsTitle = styled.span`
  font-size: 36px;
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.price.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.price.color};
  margin: 16px 0;
  padding: 0 12px;

  @media (max-width: 678px)  {
    font-size: 24px;
  }
`

export const StyledListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 12px;

`
export const StyledColumn = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;

  @media (max-width: 678px)  {
    width: 100%;
  }
`
export const StyledLine = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
`

export const StyledLineHeader = styled.span`
  min-width: 180px;
  width: 180px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.generic.colors.secondary };
`

export const StyledLineValue = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.generic.colors.secondary };
  word-break: break-word;
`

