import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Vehicle } from '../../App';
import { StyledChevronSvg, StyledActions, StyledContainer, StyledGoBack, StyledImages, StyledInfo, StyledShareContainer, StyledShareSvg, StyledLinkCopied, StyledBackground } from './VehicleDetails.styles';
import ImageGallery from "react-image-gallery";
import { Link } from 'react-router-dom';
import VehicleSeller from '../../components/VehicleSeller/VehicleSeller';
import VehicleResumeComponent from '../../components/VehicleResumeComponent/VehicleResumeComponent';
import VehicleDetailsComponent from '../../components/VehicleDetailsComponent/VehicleDetailsComponent';
import { getFirstDetailColumn, getSecondDetailColumn } from './VehicleDetails.utils';
import VehicleEquipmentComponent from '../../components/VehicleEquipmentComponent/VehicleEquipmentComponent';
import FormModal from '../../components/Modals/FormModal/FormModal';

interface VehicleDetailProps {
  vehicles: Vehicle[];
}

export interface ILineColumn { 
  header: string;
  value: string;
}

const VehicleDetails: FC<VehicleDetailProps> = ({ vehicles }) => {
  const { id } = useParams<{ id: string }>();
  const [showShareMsg, setShowShareMsg] = useState(false);
  const [retoma, setRetoma] = useState(false);
  
  const vehicle = id ? vehicles?.find(vehicle => vehicle.vehicle_id === id) : null;

  console.log(vehicle)
  if (!vehicle) {
    return <div>vehicle not found</div>;
  }

  const firstColumn = getFirstDetailColumn(vehicle);
  const secondColumn = getSecondDetailColumn(vehicle);

  const images = vehicle.imagenes.imagen?.map(image => {
    return {
      original: image.linkimagen,
      thumbnail: image.linkimagen,
    }
  });

  const handleShowShareMsg = () => {
    setShowShareMsg(true);

    setTimeout(() => {
      setShowShareMsg(false);
    }, 2000);
  }

  return (
    <>
      <StyledContainer>
        <StyledActions>
          <Link to={'/'}>
            <StyledChevronSvg />
            <StyledGoBack>Voltar</StyledGoBack>
          </Link>
          <StyledShareContainer>
            {showShareMsg ? <StyledLinkCopied>Link copiado com sucesso</StyledLinkCopied> : <></>}
            <StyledShareSvg onClick={handleShowShareMsg} />
          </StyledShareContainer>
        </StyledActions>
      </StyledContainer>
      <StyledContainer>
        <StyledImages>
          <ImageGallery items={images} showPlayButton={false} lazyLoad={true}
            showBullets={true} />
        </StyledImages>
        <StyledInfo>
          <VehicleResumeComponent vehicle={vehicle} />
          <VehicleSeller vehicle={vehicle} setRetoma={setRetoma} />
        </StyledInfo>
      </StyledContainer>
      <VehicleDetailsComponent firstColumn={firstColumn} secondColumn={secondColumn}/>
      <StyledBackground>
        <VehicleEquipmentComponent equipment={vehicle.equipamiento_standard} />
      </StyledBackground>
      <FormModal title={vehicle.sede_publicacion} type={retoma ? "retoma" : "seller"} />
    </>
  );
};

export default VehicleDetails;
