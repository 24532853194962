import styled from "styled-components";

export const StyledBackground = styled.div`
  background-color: ${({ theme }) => theme.vehicleList.backgroundColor};
`

export const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.generic.container.maxWidth};
  padding: 0 12px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
`