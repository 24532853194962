import React, { ChangeEvent, useState } from 'react';
import { StyledApplyContainer, StyledApplyFiltersButton, StyledBackground,StyledButton, StyledButtonContainer, StyledChevronSvg, StyledContainer, StyledFilterHeader, StyledFilterSvg, StyledInput, StyledTitle } from './Filter.styles';
import { removeProperty } from './Filter.utils';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import { setStorageFilters } from '../../services/storage.service';
import { IFilterOptions } from '../../pages/VehicleList/VehicleList';

interface FilterProps {
  filters: any;
  setFilters: (filters: any) => void;
  options: IFilterOptions;
}

const Filter: React.FC<FilterProps> = ({ filters, setFilters, options }) => {
  const [changedMarca, setChangedMarca] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newFilters = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    setFilters(newFilters);
    setStorageFilters(newFilters);
  };

  const handleFilter = (filter: string, value: string[]) => {
    const { [filter]: _, ...rest } = filters;
    let newFilters = {...rest};
    if (filter === 'marca') {
      newFilters = removeProperty("modelo", newFilters);
      // newFilters = removeProperty("combustible", newFilters);
      // newFilters = removeProperty("cambio", newFilters);
      setChangedMarca(!changedMarca);
    }
    newFilters[filter] = value;
    setFilters(newFilters);
    setStorageFilters(newFilters);
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  }

  return (
    <>
      <StyledButtonContainer>
        <StyledButton onClick={toggleFilters}>
          <StyledFilterSvg />
          Filtros
        </StyledButton>
      </StyledButtonContainer>
      <StyledBackground show={showFilters}>
        {showFilters && 
          <StyledFilterHeader>
            <StyledChevronSvg onClick={toggleFilters}/>
            <StyledTitle>Filtros</StyledTitle>
          </StyledFilterHeader>
        }
        <StyledContainer>
          <DropdownSelect 
            id="marca"
            options={options.marca}
            value={filters.marca}
            placeholder="Marca"
            clearFilter={clearAll}
            handleClick={handleFilter}/>
          <DropdownSelect 
            id="modelo"
            options={options.modelo}
            placeholder="Modelo"
            value={filters.modelo}
            disabled={!filters?.marca?.length || filters?.marca?.length > 1}
            clearFilter={changedMarca}
            handleClick={handleFilter}/>
          <DropdownSelect 
            id="combustible"
            options={options.combustible}
            placeholder="Combustível"
            value={filters.combustible}
            clearFilter={clearAll}
            handleClick={handleFilter}/>
          <DropdownSelect 
            id="cambio"
            options={options.cambio}
            placeholder="Transmissão"
            value={filters.cambio}
            clearFilter={clearAll}
            handleClick={handleFilter}/>

          <StyledInput type="text" name="priceSince" placeholder="Preço de" value={filters.priceSince} onChange={handleChange} />
          <StyledInput type="text" name="priceUntil" placeholder="Preço até" value={filters.priceUntil} onChange={handleChange} />
          <StyledInput type="text" name="kmsSince" placeholder="Quilómetros de" value={filters.kmsSince} onChange={handleChange} />
          <StyledInput type="text" name="kmsUntil" placeholder="Quilómetros até" value={filters.kmsUntil} onChange={handleChange} />
          <StyledInput type="text" name="yearSince" placeholder="Ano de" value={filters.yearSince} onChange={handleChange} />
          <StyledInput type="text" name="yearUntil" placeholder="Ano até" value={filters.yearUntil} onChange={handleChange} />
        </StyledContainer>
        {showFilters && 
          <StyledApplyContainer>
            <StyledApplyFiltersButton type="submit" onClick={toggleFilters}>Aplicar Filtros</StyledApplyFiltersButton>
          </StyledApplyContainer>
        }
      </StyledBackground>
    </>
  );
};

export default Filter;