import React from 'react';
import { Vehicle } from '../../App';
import { StyledChipsContainer, StyledVehicleChip } from '../VehicleSummary/VehicleSummary.styles';
import { convertMonthToPortuguese, formatNumberWithSpaces } from '../VehicleSummary/VehicleSummary.utils';
import { StyledVehiclePrice, StyledContainer, StyledWarranty, StyledVehicleName, StyledVehicleVersion, StyledWarrantyHighlight, StyledHighlightedVehicle, StyledHighlightedContainer } from './VehicleResumeComponent.styles';

interface VehicleDetailProps {
  vehicle: Vehicle;
}

const VehicleResumeComponent: React.FC<VehicleDetailProps> = ({ vehicle }) => {

  return (
      <StyledContainer>
        { vehicle.destacado === '1' ? 
          <StyledHighlightedContainer>
            <StyledHighlightedVehicle>Destacado</StyledHighlightedVehicle>
          </StyledHighlightedContainer>
          :<></>}
        <StyledVehicleName>{vehicle.marca} {vehicle.modelo}</StyledVehicleName>
        <StyledVehicleVersion>{vehicle.version}</StyledVehicleVersion>
        <StyledChipsContainer>
          {vehicle.combustible.length > 0 && 
            <StyledVehicleChip>
              {vehicle.combustible}
            </StyledVehicleChip>
          }
          {vehicle.cambio &&
            <StyledVehicleChip>
              {vehicle.cambio}
            </StyledVehicleChip>
          }
          {(vehicle.month || vehicle.year) &&
            <StyledVehicleChip>
            {convertMonthToPortuguese(parseInt(vehicle.month))} {vehicle.year}
            </StyledVehicleChip>
          }
          {vehicle.km &&
            <StyledVehicleChip>
              {vehicle.km} km
            </StyledVehicleChip>
          }
        </StyledChipsContainer>
        {vehicle.garantia ? 
          <StyledWarranty>Garantia: <StyledWarrantyHighlight>{vehicle.garantia} meses</StyledWarrantyHighlight>
          </StyledWarranty>
          : <StyledWarranty>Sem garantia</StyledWarranty>}
        <StyledVehiclePrice>{formatNumberWithSpaces(parseInt(vehicle.precio))}€</StyledVehiclePrice>
      </StyledContainer>
  );
};

export default VehicleResumeComponent;