import styled from "styled-components";
import { ReactComponent as HelpIcon} from '../../assets/question.svg'

export const StyledPage = styled.div`
  background-color: ${({ theme }) => theme.vehicleList.backgroundColor};
`

export const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.generic.container.maxWidth};
  margin: 0 auto;
  display: flex;
  width: 100%;
  padding: 24px 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const StyledTitle = styled.span`
  font-size: ${({ theme }) => theme.vehicleList.pageTitle.fontSize};
  font-weight: ${({ theme }) => theme.vehicleList.pageTitle.fontWeight};
  color: ${({ theme }) => theme.vehicleList.pageTitle.color};

  @media (max-width: 678px)  {
    font-size: 24px;
  }
`

export const StyledHighlight = styled.span`
  color: ${({ theme }) => theme.vehicleList.pageTitle.highlight.color};
  font-size: 20px;
  vertical-align: top;
`

export const StyledPagination = styled.div`
  max-width: ${({ theme }) => theme.generic.container.maxWidth};
  margin: 24px auto;
  padding: 0 12px;
  display: flex;
  justify-content: center;

  .page-link {
    cursor: pointer;
    color: ${({ theme }) => theme.pagination.color}
  }

  .page-link.active, > .page-link {
    z-index: 3;
    color: ${({ theme }) => theme.pagination.color} !important;
    background-color: ${({ theme }) => theme.pagination.backgroundColor} !important;
    border-color: ${({ theme }) => theme.pagination.backgroundColor} !important;
  }

  .page-link.active, .active > .page-link {
    z-index: 3;
    color: ${({ theme }) => theme.pagination.hover.color} !important;
    background-color: ${({ theme }) => theme.pagination.hover.backgroundColor} !important;
    border-color: ${({ theme }) => theme.pagination.hover.backgroundColor} !important;
  }
`

export const StyledHelp = styled.div`
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const StyledHelpIcon = styled(HelpIcon)`
  fill: white;
  width: 28px;
  height: 28px;
`
