import styled from "styled-components";
import { ReactComponent as CheckIcon} from '../../assets/check.svg'

export const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.generic.container.maxWidth};
  margin: 24px auto 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px 0 48px 0;

  .accordion {
    --bs-accordion-border-color: none;
    --bs-accordion-border-width: none;
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-btn-bg: ${({ theme }) => theme.vehicleDetail.backgroundColor};
  }

  .accordion-button:not(.collapsed) {
    color: ${({ theme }) => theme.generic.colors.secondary};
    background-color: ${({ theme }) => theme.vehicleDetail.backgroundColor};
    box-shadow: 0 1px 0 ${({ theme }) => theme.generic.colors.primary};
  
    &:focus {
      outline: none;
    }
  }

  .accordion-button {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.generic.colors.secondary};

    @media (max-width: 480px)  {
      font-size: 16px;
    }
  }

  .accordion-item {
    background-color: ${({ theme }) => theme.vehicleDetail.backgroundColor};
  }
`

export const StyledDetailsTitle = styled.span`
  font-size: 36px;
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.price.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.price.color};
  margin: 16px 0 32px 0;
  padding: 0 12px;

  @media (max-width: 678px)  {
    font-size: 24px;
  }
`
export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  padding: 0 12px;
`

export const StyledFeature = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.generic.colors.secondary };

  @media (max-width: 480px)  {
    font-size: 14px;
  }
`

export const StyledCheckSvg = styled(CheckIcon)`
  fill: ${({ theme }) => theme.filters.checkbox};
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 12px;
`