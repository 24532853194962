import styled from 'styled-components';
import { ReactComponent as TrashIcon} from '../../assets/trash.svg'
import { StyledHeader } from '../Modals/FormModal/FormModal.styles';


export const StyledContainer = styled.div`
  width: 48%;
  margin: 12px 0;
`;
export const StyledButton = styled.button<{variant: string, hasError: boolean}>`
  height: 50px;
  color: ${({ theme, variant }) => variant === 'secondary' ? theme.generic.colors.secondary : "white"};
  background-color: ${({ theme, variant }) => variant === 'secondary' ? 'transparent' : theme.generic.button.backgroundColor};
  border: ${({ theme, variant }) => variant === 'secondary' ? '1.5px solid' + theme.generic.colors.darkGradient : '1px solid ' + theme.generic.button.backgroundColor};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  font-weight: ${({ variant }) => variant === 'secondary' ? "500" : "400"};

  ${({ hasError }: { hasError: boolean }) => hasError && `border: 1px solid red; color: red;`}
`;

export const StyledDeleteButton = styled.button`
  position: absolute;
  top: -14px;
  right: -8px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCloseSvg = styled(TrashIcon)`
  fill: white;
  width: 16px;
  height: 16px;
`

export const StyledTitle = styled(StyledHeader)`
  margin: 12px 0;
`


