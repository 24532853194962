import styled from "styled-components";
import { ReactComponent as Share} from '../../assets/share-fill.svg'
import { ReactComponent as ChevronLeft} from '../../assets/chevron-left.svg'


export const StyledBackground = styled.div`
  background-color: ${({ theme }) => theme.vehicleDetail.backgroundColor};
`

export const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.generic.container.maxWidth};
  margin: 0 auto;
  display: flex;
  width: 100%;
  padding: 0 12px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
`

export const StyledImages = styled.div`
  width: 64%;

  @media (max-width: 1000px)  {
    width: 56%; 
  }
  @media (max-width: 678px)  {
    width: 100%; 
  }
`
export const StyledInfo = styled.div`
  width: 34%;

  @media (max-width: 1000px)  {
    width: 40%; 
  }
  @media (max-width: 678px)  {
    width: 100%; 
  }
`
export const StyledActions = styled.div`
  margin: 16px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledShareContainer = styled.div`
  font-size: 14px;
`

export const StyledShareSvg = styled(Share)`
  fill: ${({ theme }) => theme.generic.colors.primary};
  width: 28px;
  height: 28px;
  cursor: pointer;
`

export const StyledGoBack = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.generic.colors.secondary};
  vertical-align: text-top;
  
  &:hover {
    font-weight: 700;
  }
`
export const StyledLinkCopied = styled.span`
  margin-right: 24px;
  font-size: 16px;
  font-weight: 400;
  color: white;
  vertical-align: text-top;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.generic.colors.secondary};
`

export const StyledChevronSvg = styled(ChevronLeft)`
  fill: ${({ theme }) => theme.generic.colors.primary};
  width: 24px;
  height: 24px;
  margin-right: 8px;
`




