// App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import VehicleDetails from './pages/VehicleDetails/VehicleDetails';
import { CarpickTheme } from './themes/carpick.theme';
import { GeneralTheme } from './themes/general.theme';
import { ThemeProvider } from 'styled-components';
import VehicleList from './pages/VehicleList/VehicleList';
import { setStorageVehicles, getStorageVehicles } from './services/storage.service';

// export interface Vehicle {
//   id: number;
//   make: string;
//   model: string;
//   year: number;
//   price: number;
//   color: string;
// }

export interface IEquipment {
  ficha_tecnica: {
    elemento: string[];
  },
  exterior: {
    elemento: string[];
  }
  interior: {
    elemento: string[];
  }
  multimedia: {
    elemento: string[];
  }
  confort: {
    elemento: string[];
  }
  seguridad: {
    elemento: string[];
  }
}
export interface Vehicle {
  vehicle_id: string;
  concesionario: string;
  sede: string;
  ubicacion: string;
  matricula: string;
  bastidor: string;
  marca: string;
  modelo: string;
  version: string;
  fecha_matriculacion: string;
  precio: string;
  precio_anterior: string;
  precio_financiado: string;
  cuota_financiacion: string;
  cantidad_minima_financiar: string;
  plazo_minimo_financiar: [],
  pvd: string;
  precio_vn: string;
  iva_deducible: string;
  km: string;
  potencia: string;
  potenciaKW: string;
  cilindrada: string;
  puertas: string;
  plazas: string;
  peso: [],
  maletero: string;
  deposito: [],
  aceleracion: [],
  velocidad_maxima: [],
  garantia: string;
  garantia_fabricante: [],
  programa_marca: string;
  libro_servicio: string;
  nacional: [],
  distintivo_ambiental: [],
  eficiencia_energetica: [],
  year: string;
  month: string;
  combustible: string | string[];
  emisiones: [],
  consumos: {
    consumo_carretera: [],
    consumo_urbano: [],
    consumo_mixto: string;
  },
  medidas: {
    largo: [],
    ancho: [],
    alto: []
  },
  cambio: string;
  color: string;
  comentarios: [],
  carrocerias: {
    carroceria: string;
  },
  procedencia: string;
  carfax: string;
  fecha_vto_itv: string;
  fecha_ultima_itv: string;
  resultado_itv: [],
  extras: [],
  reservado: string;
  vendido: string;
  fecha_modificacion: string;
  destino_venta: string;
  km0: string;
  destacado: string;
  oferta_especial: string;
  sede_publicacion: string;
  imagenes: {
    imagen: {
      linkimagen: string;
      checksum: string;
    }[];
  },
  equipamiento_standard: IEquipment;
}

const App: React.FC = () => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  // general or carpick
  const [theme, setTheme] = useState('general');

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'general' ? 'carpick' : 'general'));
  };

  const currentTheme = theme === 'general' ? GeneralTheme : CarpickTheme;

  useEffect(() => {
    if (vehicles.length === 0) {
      const cachedVehicles = getStorageVehicles();

      console.log(cachedVehicles)

      if (!cachedVehicles) {
        fetch("https://wpdev.gobig.pt/wp-json/maxterauto/v1/fetch-xml?url=https%3A%2F%2Fresources.maxterauto.com%2FFEED%2FmaxterautoVO%2F%3Faccess_token%3D4c5bde74a8f110656874902f07378009", {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setStorageVehicles(data.vehiculo);
            setVehicles(data.vehiculo);
          })
          .catch((error) => console.log(error));
      } else if (cachedVehicles) {
        setVehicles(cachedVehicles);
      }
    }
  }, []);
  
  return (
    <ThemeProvider theme={currentTheme}>
      <div className="toggle-to-erase">
        <label className="switch">
          <input type="checkbox" onClick={toggleTheme}/>
          <span className="slider round"></span>
        </label>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <VehicleList vehicles={vehicles} carpick={theme === 'carpick'} />
          }></Route>
          <Route path="/:id" element={<VehicleDetails vehicles={vehicles} />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
