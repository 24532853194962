export const convertMonthToPortuguese = (month: number | undefined): string => {
  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  if (month === undefined) return "";

  if (month < 1 || month > 12) {
    throw new Error("Invalid month value. Month should be between 1 and 12.");
  }

  return monthNames[month - 1];
};

export const formatNumberWithSpaces = (amount: number): string => {
  // Use Intl.NumberFormat to format the number with comma as thousand separator
  const formattedAmount = new Intl.NumberFormat('pt-PT').format(amount);
  // Replace commas with spaces
  return formattedAmount.replace(/,/g, ' ');
}