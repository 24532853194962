import React from 'react';
import { StyledColumn, StyledContainer, StyledDetailsTitle, StyledLine, StyledLineHeader, StyledLineValue, StyledListContainer } from './VehicleDetailsComponent.styles';
import { ILineColumn } from '../../pages/VehicleDetails/VehicleDetails';

interface VehicleDetailsProps {
  firstColumn: ILineColumn[];
  secondColumn: ILineColumn[];
}

const VehicleDetailsComponent: React.FC<VehicleDetailsProps> = ({ firstColumn, secondColumn }) => {

  return (
    <StyledContainer>
      <StyledDetailsTitle>Detalhes</StyledDetailsTitle>
      <StyledListContainer>
        <StyledColumn>
          {firstColumn.map((item) => 
            <StyledLine key={item.header}>
              <StyledLineHeader>{item.header}</StyledLineHeader>
              <StyledLineValue>{item.value}</StyledLineValue>
            </StyledLine>
          )}
        </StyledColumn>
        <StyledColumn>
          {secondColumn.map((item) => 
            <StyledLine key={item.header}>
              <StyledLineHeader>{item.header}</StyledLineHeader>
              <StyledLineValue>{item.value}</StyledLineValue>
            </StyledLine>
          )}
        </StyledColumn>
      </StyledListContainer>
    </StyledContainer>
  );
};

export default VehicleDetailsComponent;