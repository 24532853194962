import { Vehicle } from "../App";
import { STORAGE_FILTERS, STORAGE_SORT, STORAGE_VEHICLES } from "../AppConstants";
import { IFilters } from "../pages/VehicleList/VehicleList";

export const getStorageFilters = () => {
  const cache = sessionStorage.getItem(STORAGE_FILTERS);
  if (cache) {
    return JSON.parse(cache);
  } else {
    return null;
  }
}

export const setStorageFilters = (filters: IFilters) => {
  sessionStorage.setItem(STORAGE_FILTERS, JSON.stringify(filters));
}

export const getStorageSort = () => {
  const cache = sessionStorage.getItem(STORAGE_SORT);
  if (cache) {
    return cache;
  } else {
    return null;
  }
}

export const setStorageSort = (sort: string) => {
  sessionStorage.setItem(STORAGE_SORT, sort);
}

export const getStorageVehicles = () => {
  const cache = sessionStorage.getItem(STORAGE_VEHICLES);
  if (cache) {
    return JSON.parse(cache);
  } else {
    return null;
  }
}

export const setStorageVehicles = (vehicles: Vehicle[]) => {
  sessionStorage.setItem(STORAGE_VEHICLES, JSON.stringify(vehicles));
}