import React, { ChangeEvent, useState } from 'react';
import { StyledButton, StyledCloseSvg, StyledHeader, StyledInput, StyledModalContent, StyledSelect, StyledTextarea } from './FormModal.styles';
import UploadButton from '../../UploadButton/UploadButton';
import { EMPTY_FORM, ValidateEmail, ValidatePhoneNumber } from './FormModal.utils';

interface FormModalProps {
  title?: string;
  type?: string;
  sedeOptions?: string[];
}

export interface IGenericInput {
  value: string;
  hasError: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

export interface IModalForm {
  [key: string]: IGenericInput;
}

const ModalComponent: React.FC<FormModalProps> = ({ title, type, sedeOptions }) => {
  const [form, setForm] = useState<IModalForm>(EMPTY_FORM);
  const [formSent, setFormSent] = useState<boolean>(false);
  const [images, setImages] = useState<File[]>([]);
  const [imagesError, setImagesError] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const newForm = {
      ...form,
      [e.target.name]: {
        value: e.target.value,
        hasError: false,
      }
    }
    setForm(newForm);
  };

  const resetForm = () => {
    setForm(EMPTY_FORM);
    setImagesError(false);
    setFormSent(false);
  }

  const formValidation = () => {
    let returnedValue: boolean = true;

    if (!ValidateEmail(form.email.value)) {
      setForm((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          hasError: true,
        }
      }));
      returnedValue = false;
    }

    if (!ValidatePhoneNumber(form.phone.value)) {
      setForm((prev) => ({
        ...prev,
        phone: {
          ...prev.phone,
          hasError: true,
        }
      }));
      returnedValue = false;
    }

    let hasNullField = false;
    if (type === 'retoma') {
      hasNullField = checkNullFields(["name", "message", "marca", "model", "version", "year", "kms"]);
    } else {
      hasNullField = checkNullFields(["name", "message"]);
    }

    if (hasNullField) {
      returnedValue = false;
    }

    if (!images.length) {
      setImagesError(true);
      returnedValue = false;
    }

    return returnedValue;
  };

  const checkNullFields = (fields: string[]): boolean => {
    let returnedValue = false;
    fields.forEach(field => {
      const isNull = form[field].value ? false : true;

      setForm((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          hasError: isNull,
        }
      }));

      if (isNull) {
        returnedValue = true;
      }
    });

    return returnedValue;
  }

  const handleSubmitForm = () => {
    if (formValidation()) {
      setFormSent(true);
    }
  }
  

  return (
    <div className="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        { !formSent ?
          <StyledModalContent className="modal-content">
            <div className="d-flex justify-content-between modal-header">
              <h5 className="modal-title">{ type === 'retoma' ? 'Avaliação de Retoma' : title || 'Entre em contacto'}</h5>
              <StyledCloseSvg data-bs-dismiss="modal" aria-label="Close" onClick={resetForm} />
              {/* <button type="button" className="btn-close" ></button> */}
            </div>
            <div className="modal-body">
              {sedeOptions?.length ? 
                <StyledSelect name="sede" 
                  value={form.sede.value} 
                   onChange={handleChange}>
                    {sedeOptions.map(sede => (
                      <option key={sede} value={sede}>{sede}</option>
                    ))}
                </StyledSelect>
                : <></>
              }
              <StyledInput type="text" name="name" 
                value={form.name.value} 
                hasError={form.name.hasError} 
                placeholder="Nome*" onChange={handleChange}/>
              <StyledInput width="48%" type="email" name="email" 
                value={form.email.value} 
                hasError={form.email.hasError}
                placeholder="Email*" onChange={handleChange} />
              <StyledInput width="48%" type="number" name="phone" 
                value={form.phone.value} 
                hasError={form.phone.hasError}
                placeholder="Telemóvel*" onChange={handleChange}/>
              <StyledTextarea name="message" placeholder="Escreva aqui a sua mensagem*" 
                value={form.message.value} 
                hasError={form.message.hasError} onChange={handleChange} />
              {type === 'retoma' ?
                <>
                  <StyledHeader>Dados do seu carro</StyledHeader>
                  <StyledInput width="48%" type="text" name="marca"
                    value={form.marca.value} hasError={form.marca.hasError}
                    placeholder="Marca*" onChange={handleChange}/> 
                  <StyledInput width="48%" type="text" name="model" 
                    value={form.model.value} hasError={form.model.hasError}
                    placeholder="Modelo*" onChange={handleChange}/> 
                  <StyledInput width="48%" type="text" name="version"
                    value={form.version.value} hasError={form.version.hasError}
                    placeholder="Versão*" onChange={handleChange}/> 
                  <StyledInput width="48%" type="number" name="kms"
                    value={form.kms.value} hasError={form.kms.hasError} 
                    placeholder="Quilómetros*" onChange={handleChange}/> 
                  <StyledInput width="48%" type="number" name="year"
                    value={form.year.value} hasError={form.year.hasError} 
                    placeholder="Ano*" onChange={handleChange}/>
                  <UploadButton images={images} setImages={setImages}
                    hasError={imagesError} setHasError={setImagesError} />
                </>
                : <></>}
            </div>
            <div className="modal-footer">
              <StyledButton variant="secondary" data-bs-dismiss="modal" onClick={resetForm}>Cancelar</StyledButton>
              <StyledButton variant="primary" onClick={handleSubmitForm}>Enviar</StyledButton>
            </div>
          </StyledModalContent>
          : 
          <StyledModalContent className="modal-content">
            <div className="d-flex justify-content-between modal-header">
              <h5 className="modal-title">{ type === 'retoma' ? 'Avaliação de Retoma' : title || 'Entre em contacto'}</h5>
              <StyledCloseSvg data-bs-dismiss="modal" aria-label="Close" />
              {/* <button type="button" className="btn-close" ></button> */}
            </div>
            <div className="p-5 modal-body d-flex flex-column gap-3">
              <span>A sua mensagem foi enviada com sucesso!</span>
              <span>Obrigado pelo seu contacto.</span>
            </div>
            <div className="modal-footer d-flex justify-content-end">
              <StyledButton variant="primary" data-bs-dismiss="modal" onClick={resetForm}>Fechar</StyledButton>
            </div>
          </StyledModalContent> }
      </div>
    </div>
  );
};

export default ModalComponent;