import styled from "styled-components";
import { ReactComponent as CloseIcon} from '../../../assets/x.svg'
import ArrowIcon from '../../../assets/chevron-down.svg'
import { UIButton } from "../../ButtonComponent/ButtonComponent.styles";


export const StyledArrow = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.generic.colors.secondary};
`
export const StyledSelect = styled.select<{width?: string}>`
  height: 50px;
  color: ${({ theme }) => theme.filters.color};
  background-color: ${({ theme }) => theme.vehicleDetail.backgroundColor};
  border: 1px solid ${({ theme }) => theme.vehicleDetail.backgroundColor};
  border-radius: ${({ theme }) => theme.filters.borderRadius};
  font-weight: 400;
  padding: 10px 12px;
  margin: 12px 0;
  width: ${({ width }) => width ?  width : '100%'};

  &:focus {
    outline: none;
  }

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 17px;
`
export const StyledInput = styled.input<{width?: string, hasError: boolean}>`
  height: 50px;
  color: ${({ theme }) => theme.filters.color};
  background-color: ${({ theme }) => theme.vehicleDetail.backgroundColor};
  border: 1px solid ${({ theme }) => theme.vehicleDetail.backgroundColor};
  border-radius: ${({ theme }) => theme.filters.borderRadius};
  font-weight: 400;
  padding: 10px 12px;
  margin: 12px 0;
  width: ${({ width }) => width ?  width : '100%'};
  ${({ hasError }: { hasError: boolean }) => hasError && `border: 1px solid red;`}

  &:focus {
    outline: none;
  }
`
export const StyledTextarea = styled.textarea<{hasError: boolean}>`
  height: 120px;
  color: ${({ theme }) => theme.filters.color};
  background-color: ${({ theme }) => theme.vehicleDetail.backgroundColor};
  border: 1px solid ${({ theme }) => theme.vehicleDetail.backgroundColor};
  border-radius: ${({ theme }) => theme.filters.borderRadius};
  font-weight: 400;
  padding: 10px 12px;
  margin: 12px 0;
  width: 100%;
  ${({ hasError }: { hasError: boolean }) => hasError && `border: 1px solid red;`}

  &:focus {
    outline: none;
  }
`

export const StyledCloseSvg = styled(CloseIcon)`
  fill: ${({ theme }) => theme.generic.colors.secondary};
  width: 30px;
  height: 30px;
  cursor: pointer;
`


export const StyledButton = styled(UIButton)`
  width: 160px;
  
  @media (max-width: 480px) {
    width: 100%;
  }
`

export const StyledModalContent = styled.div`
  .modal-header {
    padding: 16px 32px;
  }
  
  .modal-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px 32px;
  }

  .modal-footer {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px 32px;
  }
`
export const StyledHeader = styled.span`
  margin: 24px 0 12px 0;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.generic.colors.secondary};
`


