import styled from "styled-components";
import { UIButton } from "../ButtonComponent/ButtonComponent.styles";

export const StyledContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.generic.separator.color};
  border-radius: 4px;
`

export const StyledSellerName = styled.span`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.header.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.header.color};
  text-decoration: none;
  margin-bottom: 36px
`

export const StyledStand = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.vehicleList.vehicle.subheader.color};
`

export const StyledButton = styled(UIButton)`
  margin-bottom: 12px;
`