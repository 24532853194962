import { IModalForm } from "./FormModal";

export const ValidateEmail = (email: string) => {
  if (!email) {
    return false;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return false;
  }
  return true;
};

export const ValidatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber || phoneNumber.length != 9) {
    return false;
  } else {
    return true;
  }
};

export const IsNullOrEmpty = (value?: string) => {
  return !value || value.trim() === "";
};

export const EMPTY_FORM: IModalForm = {
  sede: {
    value: "",
    hasError: false,
  },
  name: {
    value: "",
    hasError: false,
  },
  email: {
    value: "",
    hasError: false,
  },
  phone: {
    value: "",
    hasError: false,
  },
  message: {
    value: "",
    hasError: false,
  },
  marca: {
    value: "",
    hasError: false,
  },
  model: {
    value: "",
    hasError: false,
  },
  version: {
    value: "",
    hasError: false,
  },
  kms: {
    value: "",
    hasError: false,
  },
  year: {
    value: "",
    hasError: false,
  },
};