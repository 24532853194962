import React, { ChangeEvent, useEffect, useState } from 'react';
import { StyledChevronImg, StyledCloseSvg, StyledCounter, StyledDropdown } from './DropdownSelect.styles';
import Chevron from '../../assets/chevron-down.svg'
import { ReactComponent as Close} from '../../assets/x.svg'
import { FILTER_ALL } from '../../AppConstants';
import { getSortedObjectEntries, moveStringToFront } from './DropdownSelect.utils';
import { FilterObject } from '../../pages/VehicleList/VehicleList';

interface IDropdownProps {
  id: string;
  disabled?: boolean;
  placeholder: string;
  value: string[];
  options: FilterObject;
  handleClick: (filterId: string, value: string[]) => void;
  clearFilter: boolean;
}

interface Option {
  value: string;
  label: string;
}

const DropdownSelect: React.FC<IDropdownProps> = ({ id, placeholder, value, disabled, options, handleClick, clearFilter }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const sortedOptions = moveStringToFront(getSortedObjectEntries(options), FILTER_ALL);

  useEffect(() => {
    setSelectedOptions([]);
  }, [clearFilter])

  const label: string = function() {
    if (value && value.length > 0) {
      const firstSelected = value[0];
      const selectCount = value.length;

      if (selectCount === 1) {
        return firstSelected;
      }

      return firstSelected === FILTER_ALL ? 
        firstSelected 
        : firstSelected + ' +' + (value.length-1);
    } else {
      return placeholder;
    }
  }();

  const handleSelectAll = () => {
    let updatedSelectedOptions: string[] = [];
    if (isSelectAll) {
      updatedSelectedOptions = [];
    } else if (options){
      updatedSelectedOptions = sortedOptions.map(([key, _]) => key);
    }

    setSelectedOptions(updatedSelectedOptions);
    setIsSelectAll(!isSelectAll);
    handleClick(id, updatedSelectedOptions);
  };

  const handleOptionChange = (option: string) => {
    if (option === FILTER_ALL) {
      handleSelectAll();
      return;
    }

    let updatedSelectedOptions = [];
    if (selectedOptions.includes(option)) {
      updatedSelectedOptions = selectedOptions.filter(item => item !== option);
    } else {
      updatedSelectedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedSelectedOptions);
    setIsSelectAll(updatedSelectedOptions.length === options?.size);
    handleClick(id, updatedSelectedOptions);
  };

  const handleClear = (event: any) => {
    event.stopPropagation();
    setSelectedOptions([]);
    handleClick(id, []);
  }

  return (
    <StyledDropdown>
      <div className="dropdown">
        <button id={`dropdown-${id}`} className="d-flex justify-content-between align-items-center btn btn-secondary dropdown-toggle"
          type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" disabled={disabled}>
          <span className={value && value.length ? 'highlight' : ''}>{label}</span>
          {value?.length ? <StyledCloseSvg onClick={handleClear}  /> : <></>}
          {(value && !value.length) || !value ? <StyledChevronImg src={Chevron} /> : <></>}
        </button>
        <ul className="dropdown-menu">
          {options && sortedOptions.map(([key, value]) =>(
            <li className="ps-3" key={key}>
              <div className="form-check">
                <input id={key} className="form-check-input" type="checkbox" 
                  checked={selectedOptions.includes(key)}
                  onChange={() => handleOptionChange(key)} />
                <label className={selectedOptions.includes(key) ? 'ps-2 form-check-label highlight' : 'ps-2 form-check-label'} htmlFor={key}>
                  {key}<StyledCounter> ({value})</StyledCounter>
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </StyledDropdown>
  );
};

export default DropdownSelect;