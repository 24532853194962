export function moveStringToFront(arr: [key: string, value: number][], str: string): [key: string, value: number][] {
  const index = arr.findIndex(([key, value]) => key === str);
  const temp = arr[index];
  if (index > -1) {
    arr.splice(index, 1); // Remove the string from its original position
  }
  arr.unshift(temp); // Add the string to the start of the array
  return arr;
}

export function getSortedObjectEntries(obj: { [key: string]: number }): [string, number][] {
  const entries = Object.entries(obj);
  entries.sort((a, b) => a[0].localeCompare(b[0])); // Sort entries by keys (alphabetic order)
  return entries;
}