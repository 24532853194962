import styled from "styled-components";

export const UIButton = styled.button<{variant: string}>`
  height: 50px;
  color: ${({ theme, variant }) => variant === 'secondary' ? theme.generic.colors.secondary : "white"};
  background-color: ${({ theme, variant }) => variant === 'secondary' ? 'transparent' : theme.generic.button.backgroundColor};
  border: ${({ theme, variant }) => variant === 'secondary' ? '1.5px solid' + theme.generic.colors.darkGradient : '1px solid ' + theme.generic.button.backgroundColor};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  font-weight: ${({ variant }) => variant === 'secondary' ? "500" : "400"};
`