import React, { useContext, useEffect, useState } from 'react';
import { Vehicle } from '../../App';
import ThemeContext from '../../store/theme-context';
import { StyledContainer, StyledHelp, StyledHelpIcon, StyledHighlight, StyledPage, StyledPagination, StyledTitle } from './VehicleList.styles';
import Filter from '../../components/Filter/Filter';
import Sort from '../../components/Sort/Sort';
import VehicleListComponent from '../../components/VehicleListComponent/VehicleListComponent';
import { compareStrings, countVehicleFilter, filterProperty, filterSedeVehicles, filterValuesInput, getSedes, sortVehicles } from './VehicleList.utils';
import { getStorageFilters, getStorageSort } from '../../services/storage.service';
import FormModal from '../../components/Modals/FormModal/FormModal';

interface VehicleListProps {
  vehicles: Vehicle[];
  carpick: boolean;
}

export interface FilterObject {
  [key: string]: number;
}

export interface IFilterOptions {
  marca: FilterObject;
  modelo: FilterObject; 
  combustible: FilterObject; 
  cambio: FilterObject; 
}

export interface IFilters {
  marca?: string[];
  modelo?: string[];
  combustible?: string[];
  cambio?: string[];
  priceSince?: string;
  priceUntil?: string;
  kmsSince?: string;
  kmsUntil?: string;
  yearSince?: string;
  yearUntil?: string;
}

const VehicleList: React.FC<VehicleListProps> = ({ vehicles, carpick }) => {
  const [filters, setFilters] = useState<IFilters>({});
  const [sort, setSort] = useState<string>("");
  const [activePage, setActivePage] = useState(1);

  const sedeVehicles = filterSedeVehicles(carpick, vehicles);
  const marcaVehicles = filterProperty('marca', filters.marca || [], sedeVehicles);
  const modelVehicles = filterProperty('modelo', filters.modelo || [], marcaVehicles);
  const combustibleVehicles = filterProperty('combustible', filters.combustible || [], modelVehicles);
  const cambioVehicles = filterProperty('cambio', filters.cambio || [], combustibleVehicles);
  const valueVehicles = filterValuesInput(filters, cambioVehicles);
  const sortedVehicles = sortVehicles(sort, valueVehicles);
  const helpSedeOptions = getSedes(sedeVehicles);
  
  const mapFilterOptions: IFilterOptions = {
    marca: countVehicleFilter("marca", sedeVehicles),
    modelo: countVehicleFilter("modelo", marcaVehicles),
    combustible: countVehicleFilter("combustible", modelVehicles),
    cambio: countVehicleFilter("cambio", combustibleVehicles),
  }

  useEffect(() => {
    const savedFilters = getStorageFilters();

    if (savedFilters) {
      setFilters(savedFilters);
    }

    const savedSort = getStorageSort();

    if (savedSort) {
      setSort(savedSort);
    }
  }, [])
  
  let paginationItems: any = [];
  let vehiclesList: Vehicle[] = [];
  vehiclesList = sortedVehicles.slice((activePage-1)*30, activePage*30);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    renderPagination();
    vehiclesList = sortedVehicles.slice((activePage-1)*30, activePage*30);
  };

  const renderPagination = () => {
    paginationItems = [];
    for (let number = 1; number <= Math.ceil(sortedVehicles.length / 30); number++) {
      paginationItems.push(
        <li className={`page-item ${activePage === number ? "active" : ""}`} key={number}>
          <a className="page-link" onClick={() => handlePageChange(number)}>
            {number}
          </a>
        </li>
      );
    }
  }
  renderPagination();

  return (
    <>
      <Filter filters={filters} setFilters={setFilters} options={mapFilterOptions}/>
      <StyledContainer>
        <StyledTitle>
          Anúncios <StyledHighlight>({sortedVehicles.length})</StyledHighlight>
        </StyledTitle>
        <Sort sort={sort} setSort={setSort} />
      </StyledContainer>
      <VehicleListComponent vehicles={vehiclesList} />
      <StyledPagination>
        <ul className="pagination">
          {paginationItems}
        </ul>
      </StyledPagination>
      {/* <StyledHelp>
        <StyledHelpIcon data-bs-toggle="modal" data-bs-target="#exampleModal" />
      </StyledHelp>
      <FormModal type={"general"} sedeOptions={helpSedeOptions}/> */}
    </>
  );
};

export default VehicleList;
