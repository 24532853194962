import styled from "styled-components";
import { ReactComponent as FilterIcon} from '../../assets/sliders.svg'
import { ReactComponent as ChevronLeft} from '../../assets/chevron-left.svg'


export const StyledButtonContainer = styled.div`
  display: none;
  margin: 48px 12px 0 12px;
  @media (max-width: 480px)  {
    display: flex;
    justify-content: end;
  }
`
export const StyledButton = styled.button`
  height: 50px;
  color: ${({ theme }) => theme.filters.color};
  background-color: ${({ theme }) => theme.filters.backgroundColor};
  border: 1px solid ${({ theme }) => theme.filters.button.backgroundColor};
  border-radius: ${({ theme }) => theme.filters.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%; 
`
export const StyledApplyFiltersButton = styled.button`
  height: 50px;
  color: ${({ theme }) => theme.filters.button.color};
  background-color: ${({ theme }) => theme.filters.button.backgroundColor};
  border: 1px solid ${({ theme }) => theme.generic.colors.grey};
  border-radius: ${({ theme }) => theme.filters.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
`
export const StyledApplyContainer = styled.div`
  height: 70px;
  background-color: ${({ theme }) => theme.filters.button.container};
  align-items: center;
  justify-content: center;
  width: 100%;
  display: none;
  padding: 0 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.75);
  z-index: 1010;

  @media (max-width: 480px)  {
    display: flex;
  }
`

export const StyledTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.vehicleList.pageTitle.color};
`

export const StyledFilterHeader = styled.div`
  height: 70px;
  background-color: ${({ theme }) => theme.vehicleList.filter.backgroundColor};
  align-items: center;
  justify-content: start;
  width: 100%;
  display: none;
  padding: 0 12px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1010;

  @media (max-width: 480px)  {
    display: flex;
  }
`

export const StyledFilterSvg = styled(FilterIcon)`
  fill: ${({ theme }) => theme.filters.checkbox};
  width: 20px;
  height: 20px;
  margin-right: 12px;
`
export const StyledChevronSvg = styled(ChevronLeft)`
  fill: ${({ theme }) => theme.filters.backgroundColor};
  width: 28px;
  height: 28px;
  margin-right: 12px;
`

export const StyledBackground = styled.div<{ show: boolean}>`
  background-color: ${({ theme }) => theme.vehicleList.filter.backgroundColor};
  @media (max-width: 480px)  {
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    z-index: 1001;
    display: ${({ show }) => show ? 'flex' : 'none'};
    height: 100svh;
    padding: 36px 0;
  }
`

export const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.generic.container.maxWidth};
  margin: 0 auto;
  padding: 24px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 480px)  {
    justify-content: space-around;
    height: fit-content;
    margin-bottom: 24px;
  }
`
export const StyledInput = styled.input`
  height: 50px;
  color: ${({ theme }) => theme.filters.color};
  background-color: ${({ theme }) => theme.filters.backgroundColor};
  border: 1px solid ${({ theme }) => theme.filters.backgroundColor};
  border-radius: ${({ theme }) => theme.filters.borderRadius};
  font-weight: 700;
  padding: 10px 12px;
  margin: 12px 0;
  
  &::placeholder {
    font-weight: 400;
  }

  &:focus {
    outline: none;
  }

  width: 18%; // 4 spaces = 8%

  @media (max-width: 1000px)  {
    width: 31%; // 3 spaces = 6%
  }
  @media (max-width: 678px)  {
    width: 47%; // 2 spaces = 6%
  }
  @media (max-width: 480px)  {
    width: 100%;
  }
`
