import React, { ChangeEvent, useState } from 'react';
import { StyledChevronImg, StyledCounter, StyledDropdown } from './DropdownComponent.styles';
import Chevron from '../../assets/chevron-down.svg'
import { FILTER_ALL } from '../../AppConstants';

interface IDropdownProps {
  id: string;
  disabled?: boolean;
  placeholder: string;
  value?: string | string[];
  optionsArray?: Option[];
  optionsObj?: Record<string, number>;
  isSort?: boolean;
  multiSelection?: boolean;
  handleClick: (filterId: string, value: string | string[]) => void;
}

interface Option {
  value: string;
  label: string;
}

const DropdownComponent: React.FC<IDropdownProps> = ({ id, placeholder, value, disabled, optionsArray, optionsObj, isSort = false, multiSelection = false, handleClick }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const label: string = function() {
    if (typeof value === 'string') {
      return value;
    } else if (value && value.length > 0) {
      return value[0] + ' +' + value.length;
    } else {
      return placeholder;
    }
  }();

  const handleChange = (option: Option) => {
    // setSelectedValue(option.label);
    handleClick(id, option.value);
  }

  const handleChangeObj = (value: string) => {
    // setSelectedValue(value);
    handleClick(id, value);
  }

  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectedOptions([]);
    } else if (optionsObj){
      setSelectedOptions(Object.keys(optionsObj));
    }
    setIsSelectAll(!isSelectAll);
  };

  const handleOptionChange = (option: string) => {
    if (option === FILTER_ALL) {
      handleSelectAll();
      return;
    }

    let updatedSelectedOptions;
    if (selectedOptions.includes(option)) {
      updatedSelectedOptions = selectedOptions.filter(item => item !== option);
    } else {
      updatedSelectedOptions = [...selectedOptions, option];
    }
    
    setSelectedOptions(updatedSelectedOptions);
    setIsSelectAll(updatedSelectedOptions.length === optionsObj?.length);
    handleClick(id, selectedOptions);
  };

  return (
    <StyledDropdown sort={isSort}>
      <div className="dropdown">
        <button className="d-flex justify-content-between align-items-center btn btn-secondary dropdown-toggle"
          type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" disabled={disabled} >
          <span className={value ? 'highlight' : ''}>{value ? label : placeholder}</span>
          <StyledChevronImg src={Chevron} />
        </button>
        <ul className="dropdown-menu">
          {optionsArray && optionsArray.map(option => (
            <li key={option.value}><a className="dropdown-item" onClick={() => handleChange(option)}>{option.label}</a></li>
          ))}
          {optionsObj && Object.entries(optionsObj).map(([key, value]) =>(
            <li className="ps-3" key={key}>
              <div className="form-check">
                <input id={key} className="form-check-input" type="checkbox" 
                  checked={selectedOptions.includes(key)}
                  onChange={() => handleOptionChange(key)} />
                <label className="ps-2 form-check-label" htmlFor={key}>
                  {key}<StyledCounter> ({value})</StyledCounter>
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </StyledDropdown>
  );
};

export default DropdownComponent;