import React from 'react';
import { StyledButton, StyledCloseSvg, StyledDeleteButton } from './UploadButton.styles';

interface UploadButtonProps {
  images: File[];
  setImages: (images: any) => void;
  hasError: boolean;
  setHasError: (error: boolean) => void;
}

const UploadButton: React.FC<UploadButtonProps> = ( { images, setImages, hasError, setHasError }) => {

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImages = Array.from(e.target.files).slice(0, 4 - images.length);
      setImages((prevImages: any) => [...prevImages, ...newImages]);
      setHasError(false);
    }
  };

  const handleDeleteImage = (index: number) => {
    setImages((prevImages: any) => prevImages.filter((_: any, i: number) => i !== index));
  };

  return (
    <>
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={handleImageChange}
        disabled={images.length >= 4}
        style={{ display: 'none' }}
        width="48%"
        id="image-upload-input"
      />
      <label htmlFor="image-upload-input" style={{ width: '48%', margin: "12px 0" }}>
        <StyledButton as="span" variant="secondary" disabled={images.length >= 4} hasError={hasError}>
          {images.length >= 4 ? 'Max 4 imagens carregadas' : 'Carregar imagens'}
        </StyledButton>
      </label>
      {images.length > 0 && (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', flexWrap: 'wrap', marginTop: '28px' }}>
            {images.map((image, index) => (
              <div key={index} style={{ position: 'relative' }}>
                <img
                  src={URL.createObjectURL(image)}
                  alt={`preview-${index}`}
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
                <StyledDeleteButton
                  onClick={() => handleDeleteImage(index)}
                >
                  <StyledCloseSvg />
                </StyledDeleteButton>
              </div>
            ))}
          </div>
      )}
    </>
  );
};

export default UploadButton;