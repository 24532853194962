import { Vehicle } from "../../App";
import { FILTER_ALL } from "../../AppConstants";
import { FilterObject, IFilters } from "./VehicleList";

export function compareStrings(str1: string, str2: string): number {
  return str1.toLowerCase().localeCompare(str2.toLowerCase());
}

export function countVehicleFilter(propertyName: keyof Vehicle, vehicles: Vehicle[]): FilterObject {
  const map: FilterObject = {};
  
  map[FILTER_ALL] = vehicles.length;

  vehicles.forEach(vehicle => {
    const propertyValue = vehicle[propertyName];
    if (typeof propertyValue === "string") {
      if (map[propertyValue]) {
        map[propertyValue]++;
      } else {
        map[propertyValue] = 1;
      }
    }
  });

  const newObj = moveKeyToFront(map, FILTER_ALL);
  
  return newObj;
}

function moveKeyToFront<T extends object>(obj: T, key: keyof T): T {
  const { [key]: keyValue, ...rest } = obj;
  const newObject = { [key]: key in obj ? keyValue : null, ...rest };

  return newObject as T;
}

export const sortVehicles = (sort: string, vehicles: Vehicle[]): Vehicle[] => {
  if (sort === "price-asc") {
    return vehicles.sort((a, b) => parseInt(a.precio) - parseInt(b.precio));
  } else if (sort === "price-desc") {
    return vehicles.sort((a, b) => parseInt(b.precio) - parseInt(a.precio));
  } else if (sort === "kms-asc") {
    return vehicles.sort((a, b) => parseInt(a.km) - parseInt(b.km));
  } else if (sort === "kms-desc") {
    return vehicles.sort((a, b) => parseInt(b.km) - parseInt(a.km));
  } else if (sort === "name-asc") {
    return vehicles.sort((a, b) => compareStrings(a.marca + ' ' + a.modelo, b.marca + ' ' + b.modelo));
  } else if (sort === "name-desc") {
    return vehicles.sort((a, b) => compareStrings(b.marca + ' ' + b.modelo, a.marca + ' ' + a.modelo));
  } else if (sort === "year-asc") {
    return vehicles.sort((a, b) => parseInt(a.year) - parseInt(b.year));
  } else if (sort === "year-desc") {
    return vehicles.sort((a, b) => parseInt(b.year) - parseInt(a.year));
  } else {
    return vehicles;
  }
};

export const filterSedeVehicles = (carpick: boolean, vehicles: Vehicle[]): Vehicle[] => {
  return vehicles.filter(vehicle => {
    return (
      (carpick && vehicle.sede_publicacion.toLowerCase().includes("carpick")) ||
      (!carpick && !vehicle.sede_publicacion.toLowerCase().includes("carpick"))
    );
  });
};

export function filterProperty<F extends Array<string>, K extends keyof T, T extends Vehicle>(property: K, filter: F, vehicles: T[]): T[] {
  if (!filter.length) {
    return vehicles;
  }

  return vehicles.filter(vehicle => filter.includes(vehicle[property] as string));
};

export function filterValuesInput(filters: IFilters, vehicles: Vehicle[]): Vehicle[] {
  return vehicles.filter(vehicle => {
    return (
      (!filters.priceSince || (parseInt(vehicle.precio) >= parseInt(filters.priceSince)))
      && (!filters.priceUntil || (parseInt(vehicle.precio) <= parseInt(filters.priceUntil)))
      && (!filters.kmsSince || (parseInt(vehicle.km) >= parseInt(filters.kmsSince)))
      && (!filters.kmsUntil || (parseInt(vehicle.km) <= parseInt(filters.kmsUntil)))
      && (!filters.yearSince || (parseInt(vehicle.year) >= parseInt(filters.yearSince)))
      && (!filters.yearUntil || (parseInt(vehicle.year) <= parseInt(filters.yearUntil)))
    );
  });
};

export function getSedes(vehicles: Vehicle[]): string[] {
  const allSedes = vehicles.map(obj => obj.sede_publicacion);
  const uniqueSedes = allSedes.filter((name, index) => allSedes.indexOf(name) === index);

  return uniqueSedes.sort();
}
