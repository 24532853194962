import styled from "styled-components";

export const StyledVehicleContainer = styled.div`
  width: 32%;
  margin: 1% 0;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.vehicleList.vehicle.backgroundColor};
  border-radius: ${({ theme }) => theme.vehicleList.vehicle.borderRadius};
  border: ${({ theme }) => theme.vehicleList.vehicle.border};
  position: relative;

  @media (max-width: 678px)  {
    width: 49%;
  }
  @media (max-width: 480px)  {
    width: 100%;
  }
`

export const StyledVehicleName = styled.span`
  font-size: ${({ theme }) => theme.vehicleList.vehicle.header.fontSize};
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.header.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.header.color};
  text-decoration: none;
`

export const StyledVehicleVersion = styled.span`
  font-size: ${({ theme }) => theme.vehicleList.vehicle.subheader.fontSize};
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.subheader.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.subheader.color};
`

export const StyledVehiclePrice = styled.span`
  font-size: ${({ theme }) => theme.vehicleList.vehicle.price.fontSize};
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.price.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.price.color};
  margin-top: 12px;
`

export const StyledVehicleChip = styled.span`
  font-size: ${({ theme }) => theme.vehicleList.vehicle.details.fontSize};
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.details.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.details.color};
  background-color: ${({ theme }) => theme.vehicleList.vehicle.details.backgroundColor};
  border-radius: ${({ theme }) => theme.vehicleList.vehicle.details.borderRadius};
  padding: 4px 10px;
`

export const StyledChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding-top: 10px;
`

export const StyledImageCarousel = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  @media (max-width: 678px)  {
    height: 250px;
  }
  @media (max-width: 480px)  {
    height: 200px;
  }
`

export const StyledVehicleDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`

export const StyledHighlightedVehicle = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 8px;
  padding: 8px 12px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  background-color: ${({ theme }) => theme.generic.colors.primary};
`


