import styled from "styled-components";

export const StyledDropdown = styled.div<{sort: boolean}>`
  width: 18%; // 4 spaces = 8%

  @media (max-width: 1000px)  {
    width: 31%; // 3 spaces = 6%
  }
  @media (max-width: 678px)  {
    width: 47%; // 2 spaces = 6%
  }
  .dropdown > .btn {
    width: 100%;
    height: 50px;
    color: ${({ theme, sort }) => sort ? theme.sort.color : theme.filters.color};
    background-color: ${({ theme, sort }) => sort ? theme.sort.backgroundColor : theme.filters.backgroundColor};
    border: 1px solid ${({ theme, sort }) => sort ? theme.sort.backgroundColor : theme.filters.backgroundColor};
  }
  .highlight {
    font-weight: 500;
    color: ${({ theme }) => theme.generic.colors.secondary};
  }
  .dropdown-toggle::after {
    margin-left: 12px;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: ${({ theme }) => theme.sort.active.color};
    background-color: ${({ theme }) => theme.sort.active.backgroundColor};
  }

  .dropdown-item:hover {
    color: ${({ theme }) => theme.sort.hover.color};
    background-color: ${({ theme }) => theme.sort.hover.backgroundColor};
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    width: 100%;
  }
`

export const StyledCounter = styled.span`
  color: ${({ theme }) => theme.vehicleList.pageTitle.highlight.color};
  font-size: 14px;
  font-weight: 600;
  vertical-align: top;
`

export const StyledChevronImg = styled.img`
  margin-top: 2px;
`