import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2%;

   @media (max-width: 678px)  {
    margin-top: 24px; 
  }
`

export const StyledWarranty = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.vehicleList.vehicle.subheader.color};
  margin-top: 16px;
`
export const StyledWarrantyHighlight = styled.span`
  font-weight: 700;
`

export const StyledVehicleName = styled.span`
  font-size: 32px;
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.header.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.header.color};
  text-decoration: none;
`
  
export const StyledVehicleVersion = styled.span`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.subheader.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.subheader.color};
  margin-bottom: 16px;
`

export const StyledVehiclePrice = styled.span`
  font-size: 36px;
  font-weight: ${({ theme }) => theme.vehicleList.vehicle.price.fontWeight};
  color: ${({ theme }) => theme.vehicleList.vehicle.price.color};
  margin: 16px 0;
`

export const StyledHighlightedContainer = styled.div`
  display: flex;
  justify-content: end;
`
export const StyledHighlightedVehicle = styled.span`
  border-radius: 8px;
  padding: 6px 8px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  background-color: ${({ theme }) => theme.highlight.backgroundColor};
`
