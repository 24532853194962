import React, { useEffect, useState } from 'react';
import DropdownComponent from '../DropdownComponent/DropdownComponent';
import { setStorageSort } from '../../services/storage.service';

interface SortProps {
  sort: string;
  setSort: (sort: string) => void;
}

interface Option {
  value: string;
  label: string;
}

const Sort: React.FC<SortProps> = ({ sort, setSort }) => {
  const [sortLabel, setSortLabel] = useState("Ordenar por");

  useEffect(() => {
    handleRefreshLabel();
  }, [sort])
  
  const options: Option[] = [
    { value: "", label: "Mais recentes"},
    { value: "price-asc", label: "Preço ascendente"},
    { value: "price-desc", label: "Preço descendente"},
    { value: "kms-asc", label: "Kms ascendente"},
    { value: "kms-desc", label: "Kms descendente"},
    { value: "name-asc", label: "Ordenar A-Z"},
    { value: "name-desc", label: "Ordenar Z-A"},
  ]

  const handleRefreshLabel = () => {
    setSortLabel(options.find((option) => option.value === sort)?.label || "Ordenar por");
  }

  const handleSortChange = (id: string, value: string | string[]) => {
    if (typeof value === "string") {
      // setSortLabel(options.find((option) => option.value === value)?.label || "Ordenar por")
      setSort(value);
      setStorageSort(value);
    }
  };

  return (
    <DropdownComponent
      id="order"
      optionsArray={options}
      value={sortLabel}
      placeholder="Ordenar por"
      isSort={true}
      handleClick={handleSortChange}/>
  );
};

export default Sort;