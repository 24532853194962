import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Vehicle } from '../../App';
import { StyledChipsContainer, StyledVehicleChip, StyledVehicleContainer, StyledVehicleDetails, StyledVehicleVersion, StyledVehicleName, StyledVehiclePrice, StyledImageCarousel, StyledHighlightedVehicle } from './VehicleSummary.styles';
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import { convertMonthToPortuguese, formatNumberWithSpaces } from './VehicleSummary.utils';

interface VehicleProps {
  vehicle: Vehicle;
}

const VehicleSummary: React.FC<VehicleProps> = ({ vehicle }) => {

  const [loaded, setLoaded] = useState(false);

  const handleSetLoaded = () => {
    setLoaded(true);
  }

  const images = vehicle.imagenes.imagen?.slice(0, 5)?.map(image => {
    return {
      original: image.linkimagen,
      thumbnail: image.linkimagen,
    }
  });

  return (
    <StyledVehicleContainer key={vehicle.vehicle_id}>
      {/* <div className={loaded ? 'show' : 'hidden'}> */}
        {/* <ImageGallery items={images} showPlayButton={false} lazyLoad={true} onImageLoad={handleSetLoaded}
          showBullets={true} showFullscreenButton={false} showThumbnails={false} /> */}
      {/* </div> */}
      { vehicle.destacado === '1' ? <StyledHighlightedVehicle>Destacado</StyledHighlightedVehicle>:<></>}
      <StyledImageCarousel src={vehicle.imagenes?.imagen?.[0]?.linkimagen} />
      <StyledVehicleDetails>
        <Link to={`/${vehicle.vehicle_id}`}>
          <StyledVehicleName>{vehicle.marca} {vehicle.modelo}</StyledVehicleName>
        </Link>
        <StyledVehicleVersion>{vehicle.version}</StyledVehicleVersion>
        <StyledChipsContainer>
          {vehicle.combustible.length > 0 && 
            <StyledVehicleChip>
              {vehicle.combustible}
            </StyledVehicleChip>
          }
          {vehicle.cambio &&
            <StyledVehicleChip>
              {vehicle.cambio}
            </StyledVehicleChip>
          }
          {(vehicle.month || vehicle.year) &&
            <StyledVehicleChip>
            {convertMonthToPortuguese(parseInt(vehicle.month))} {vehicle.year}
            </StyledVehicleChip>
          }
          {vehicle.km &&
            <StyledVehicleChip>
              {vehicle.km} km
            </StyledVehicleChip>
          }
        </StyledChipsContainer>
        <StyledVehiclePrice>{formatNumberWithSpaces(parseInt(vehicle.precio))}€</StyledVehiclePrice>
      </StyledVehicleDetails>
    </StyledVehicleContainer>
  );
};

export default VehicleSummary;