import { Vehicle } from "../../App";
import { convertMonthToPortuguese } from "../../components/VehicleSummary/VehicleSummary.utils";
import { ILineColumn } from "./VehicleDetails";

export function getFirstDetailColumn(vehicle: Vehicle): ILineColumn[] {
  let column: ILineColumn[] = [];

  column = [
    { header: "Marca", value: vehicle.marca},
    { header: "Modelo", value: vehicle.modelo},
    { header: "Versão", value: vehicle.version},
    { header: "Combustível", value: typeof vehicle.combustible === 'string' ? vehicle.combustible : vehicle?.combustible?.[0]},
    { header: "Mês de Registo", value: convertMonthToPortuguese(parseInt(vehicle.month))},
    { header: "Ano", value: vehicle.year},
    { header: "Quilómetros", value: vehicle.km ? vehicle.km + ' km' : ''},
    { header: "Cilindrada", value: vehicle.cilindrada ? vehicle.cilindrada + ' cm3' : '' },
    { header: "Potência", value: vehicle.potencia ? vehicle.potencia + ' cv' : ''},
    { header: "Segmento", value: vehicle.carrocerias.carroceria},
  ];
  return column;
}

export function getSecondDetailColumn(vehicle: Vehicle): ILineColumn[] {
  let column: ILineColumn[] = [];

  column = [
    { header: "Cor", value: vehicle.color},
    { header: "Mudanças", value: vehicle.cambio},
    { header: "Nº de portas", value: vehicle.puertas},
    { header: "Lotação", value: vehicle.plazas},
    { header: "Bagageira", value: vehicle.maletero ? vehicle.maletero + ' litros' : '' },
    { header: "Garantia de Stand", value: vehicle.garantia ? vehicle.garantia + ' meses' : ''},
    { header: "Origem", value: vehicle.procedencia},
    { header: "Matrícula", value: vehicle.matricula},
    { header: "VIN", value: vehicle.bastidor},
  ];
  return column;
}