import React from 'react';
import { Link } from 'react-router-dom';
import { Vehicle } from '../../App';
import { StyledContainer, StyledBackground } from './VehicleListComponent.styles';
import VehicleSummary from '../VehicleSummary/VehicleSummary';

interface VehicleListProps {
  vehicles: Vehicle[];
}

const VehicleListComponent: React.FC<VehicleListProps> = ({ vehicles }) => {

  return (
    <StyledBackground>
      <StyledContainer>
        {vehicles.map(vehicle => (
          <VehicleSummary vehicle={vehicle} key={vehicle.vehicle_id} />
        ))}
      </StyledContainer>
    </StyledBackground>
  );
};

export default VehicleListComponent;
